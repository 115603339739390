



































































































































































import { Component, Vue } from 'vue-property-decorator';
import BrandStore from '@/store/modules/brand';
import {
  EquipmentType,
  EquipmentTypeList,
  MovementType,
  MovementTypeList,
  ExerciseDifficulty,
  ExerciseDifficultyList,
  ExerciseVideoStatusList,
  ExerciseVideoStatusType,
  StatusType,
  CategoryTypeList,
  CategoryType,
  ProductType,
  ProductTypeList,
} from '@/constants';
import { BrandDocument, ExerciseDocument, ExerciseModel, IExerciseBrandSetup, IQpoint, QpointDocument, QpointModel } from '@/models';
import { S3ObjectInput } from '@/API';
import { VideoViewer } from '@/components';
import { Storage } from 'aws-amplify';
import { Helper, CSV } from '@/util';
import { saveAs } from 'file-saver';
import { canManage, canHandleStatus } from './util';

interface IExerciseSearch {
  keyword: string;
  movementTypeList: MovementType[];
  equipmentTypeList: EquipmentType[];
  difficulty: ExerciseDifficulty;
  statusList: StatusType<ExerciseVideoStatusType>[];
  categoryList: CategoryType[];
  brandList: string[];
  withoutWorkout: boolean;
  filmDateRange: Date[];
}

interface IExercisePopulatesBrandSetup {
  brand: BrandDocument;
  setup: IExerciseBrandSetup | undefined;
}

@Component({
  name: 'ExerciseList',
  components: {
    VideoViewer,
  },
})
export default class extends Vue {
  private isSearching: boolean = false;
  private results: ExerciseDocument[] = [];
  private resultSlice: ExerciseDocument[] = [];
  private resultSliceSize: number = 50;
  private totalResults: number = 0;
  private videoDialogIsOpen: boolean = false;
  private videoTitle: string = (undefined as unknown) as string;
  private videoFile: S3ObjectInput | null = (undefined as unknown) as S3ObjectInput;
  private videoUrl: string | null = null;
  private exerciseHrefs: string[] = [];
  private windowOpenTimeout: any = null;
  private page: number = 1;
  private canManage: boolean = (undefined as unknown) as boolean;

  private search: IExerciseSearch = {
    keyword: '',
    movementTypeList: [],
    equipmentTypeList: [],
    difficulty: (undefined as unknown) as ExerciseDifficulty,
    statusList: [],
    categoryList: [],
    brandList: [],
    withoutWorkout: false,
    filmDateRange: [],
  };

  private exerciseVideoStatusList: Record<ExerciseVideoStatusType, StatusType<ExerciseVideoStatusType>> = (undefined as unknown) as Record<
    ExerciseVideoStatusType,
    StatusType<ExerciseVideoStatusType>
  >;
  private movementTypeList: Record<MovementType, string> = (undefined as unknown) as Record<MovementType, string>;
  private equipmentTypeList: Record<EquipmentType, string> = (undefined as unknown) as Record<EquipmentType, string>;
  private exerciseDifficultyList: Record<ExerciseDifficulty, string> = (undefined as unknown) as Record<ExerciseDifficulty, string>;
  private brands: BrandDocument[] = (undefined as unknown) as BrandDocument[];
  private categoryTypes: Record<string, string> = (undefined as unknown) as Record<string, string>;

  public created() {
    this.movementTypeList = MovementTypeList;
    this.equipmentTypeList = EquipmentTypeList;
    this.exerciseVideoStatusList = ExerciseVideoStatusList;
    this.exerciseDifficultyList = ExerciseDifficultyList;
    this.brands = BrandStore.list;
    const categoryWithBrandPredicate = ([category]: string[]) => this.brands.some(brand => category === brand.category);
    this.categoryTypes = Object.fromEntries(Object.entries(CategoryTypeList).filter(categoryWithBrandPredicate));
    this.canManage = canManage;
    this.addSearchEvent(); // Run search after window closed
  }

  public mounted() {
    this.doSearch();
  }

  private getMovementTypeList(movementTypes: MovementType[]): string[] {
    return movementTypes.reduce((accumulator, value: MovementType) => [...accumulator, MovementTypeList[value]], [] as string[]);
  }

  public sortMovementTypes(ex1: ExerciseDocument, ex2: ExerciseDocument) {
    const movementTypes1 = this.getMovementTypeList(ex1.movementTypes).join(', ');
    const movementTypes2 = this.getMovementTypeList(ex2.movementTypes).join(', ');
    return movementTypes1.toLowerCase().localeCompare(movementTypes2.toLowerCase());
  }

  private getProductTypes(productTypes: ProductType[]): string[] {
    return productTypes.reduce((accumulator, value: ProductType) => [...accumulator, ProductTypeList[value]], [] as string[]);
  }

  private getEquipmentList(equipment: EquipmentType[]): string[] {
    return equipment.reduce((accumulator, value: EquipmentType) => [...accumulator, EquipmentTypeList[value]], [] as string[]);
  }

  public sortEquipmentTypes(ex1: ExerciseDocument, ex2: ExerciseDocument) {
    const equipmentTypes1 = this.getEquipmentList(ex1.equipmentTypes).join(', ');
    const equipmentTypes2 = this.getEquipmentList(ex2.equipmentTypes).join(', ');
    return equipmentTypes1.toLowerCase().localeCompare(equipmentTypes2.toLowerCase());
  }

  private getExerciseBrand(exercise: ExerciseDocument, brandId: string): IExerciseBrandSetup | undefined {
    return exercise.brandSetup.find(brand => brand.brandId === brandId);
  }

  private canHandleStatus(status: ExerciseVideoStatusType): boolean {
    return canHandleStatus(status);
  }

  private canHandleAnyStatus(exercise: ExerciseDocument): boolean {
    return exercise.brandSetup.some(x => this.canHandleStatus(x.status));
  }

  private isBrandSelected(brand: BrandDocument) {
    if (!this.isCategorySelected(brand.category)) {
      return false;
    }
    return this.search.brandList.length === 0 || this.search.brandList.includes(brand.id);
  }

  private getAvailableBrands(brands: BrandDocument[]) {
    const selectedCategoryTypes = this.getSelectedCategories() as string[];
    const availableBrands = brands.filter(brand => Object.keys(selectedCategoryTypes).includes(brand.category));
    availableBrands.sort((a, b) => ('' + a.name).localeCompare(b.name));
    return availableBrands;
  }

  private getVisibleBrandCols(category: CategoryType, brands: BrandDocument[]) {
    return brands.filter(brand => brand.category === category && this.isBrandSelected(brand));
  }

  private isCategorySelected(category: CategoryType) {
    return this.search.categoryList.length === 0 || this.search.categoryList.includes(category);
  }

  private getVisibleCategoryCols() {
    if (this.search.brandList.length === 0) {
      return this.getSelectedCategories();
    }

    const categoryList: CategoryType[] = this.search.categoryList.length > 0 ? this.search.categoryList : (Object.keys(this.categoryTypes) as CategoryType[]);
    const categories: CategoryType[] = categoryList.filter(categoryId => {
      const visibleBrands = this.getVisibleBrandCols(categoryId, this.brands);
      return visibleBrands.length > 0;
    });
    return this.getSelectedCategories(categories);
  }

  private getSelectedCategories(filteredCategories: CategoryType[] = this.search.categoryList) {
    if (filteredCategories.length === 0) {
      return this.categoryTypes;
    }
    const categoryTypes: any = {};
    for (const categoryId of filteredCategories) {
      const categoryLabel = this.categoryTypes[categoryId];
      categoryTypes[categoryId] = categoryLabel;
    }
    return categoryTypes;
  }

  private async handleCategoryChange(): Promise<void> {
    this.search.brandList = [];
    return this.doSearch();
  }

  private async doSearch(): Promise<void> {
    if (this.isSearching) {
      return;
    }

    this.isSearching = true;

    const _queryFilters = [];

    if (this.search.keyword !== '') {
      const codeNameQuery = this.buildCodeNameQuery(this.search.keyword.toLowerCase().trim());
      _queryFilters.push(codeNameQuery);
    }

    if (this.search.categoryList.length > 0 || this.search.brandList.length > 0 || this.search.statusList.length > 0) {
      let brandFilters: any;
      let searchBrandList = this.search.brandList;

      // Build search brand list based on category selected
      if (this.search.categoryList.length > 0) {
        const searchBrands = this.brands.filter(brand => {
          // Check if brand selected
          if (this.search.brandList.length > 0 && !this.search.brandList.find(searchBrand => searchBrand === brand.id)) {
            return false;
          }
          return this.search.categoryList.indexOf(brand.category) > -1;
        });
        searchBrandList = searchBrands.map(brand => brand.id);
      }

      /**
       * Search for both brand and status
       */
      if (searchBrandList.length > 0 && this.search.statusList.length > 0) {
        brandFilters = searchBrandList.reduce((brandAccumulator, brandId) => {
          const brandStatusMap = this.search.statusList.reduce(
            (statusAccumulator, status) => [...statusAccumulator, { searchBrandStatusList: { contains: `${brandId}|${status}` } }],
            [] as any[],
          );
          return [...brandAccumulator, ...brandStatusMap];
        }, [] as any[]);

        /**
         * Just status
         */
      } else if (this.search.statusList.length > 0) {
        brandFilters = this.search.statusList.reduce((accumulator, status) => [...accumulator, { searchStatusList: { contains: status } }], [] as any[]);

        /**
         * Just brands
         */
      } else if (searchBrandList.length > 0) {
        brandFilters = searchBrandList.reduce((accumulator, brandId) => [...accumulator, { searchBrandList: { contains: brandId } }], [] as any[]);
      }
      _queryFilters.push({ or: brandFilters });
    }

    if (this.search.difficulty) {
      _queryFilters.push({ difficulty: { eq: this.search.difficulty } });
    }

    const movementTypes = this.search.movementTypeList;
    if (movementTypes.length > 0) {
      _queryFilters.push(this.jsonListQuery('movementTypes', movementTypes));
    }
    const equipmentTypes = this.search.equipmentTypeList;
    if (equipmentTypes.length > 0) {
      _queryFilters.push(this.jsonListQuery('equipmentTypes', equipmentTypes));
    }
    const queryFilters = _queryFilters.length === 0 ? {} : { and: _queryFilters };

    this.results = await ExerciseModel.find(queryFilters);

    /**
     * Array size search is not working (EG: { searchWorkoutList: { size: { eq: 0 } } }), so unfortunately we need to filter it here
     */
    if (this.search.withoutWorkout) {
      this.results = this.results.filter(x => x.searchWorkoutList?.length === 0);
    }

    // Todo: Refactor to be able to search by date range
    if (this.search.filmDateRange.length > 0) {
      const [fromFilmDate, toFilmDate] = this.search.filmDateRange;
      const dateRangePredicate = (brand: IExerciseBrandSetup) => {
        if (!brand.filmDate) {
          return false;
        }
        const filmDateAsNum = new Date(brand.filmDate).getTime();
        return filmDateAsNum >= fromFilmDate.getTime() && filmDateAsNum <= toFilmDate.getTime();
      };
      const exerciseDateRangePredicate = (exercise: ExerciseDocument) => exercise.brandSetup.some(dateRangePredicate);

      this.results = this.results.filter(exerciseDateRangePredicate);
    }

    this.totalResults = this.results.length;
    this.setPagination(1);

    this.isSearching = false;
  }

  private jsonListQuery(property: string, list: any[]): any {
    const queries = list.map(value => {
      return { [property]: { contains: value } };
    });
    return { or: queries };
  }

  private buildCodeNameQuery(codeName: string): any {
    const CODE_PREFIX = 'EX';
    const CODE_REGEX = new RegExp(`^${CODE_PREFIX}\\d+`, 'i');

    const isCode = CODE_REGEX.test(codeName);
    if (!isCode) {
      return { searchName: { contains: codeName } };
    }

    const codeOrdinalNum: string = codeName.substr(CODE_PREFIX.length);
    const code = `${CODE_PREFIX}${parseInt(codeOrdinalNum)}`;
    return { code: { contains: code } };
  }

  private getExerciseBrandVideo(exercise: ExerciseDocument, brandId: string) {
    const brandSetup = exercise.brandSetup;
    const brandData = brandSetup.find(brand => brand.brandId === brandId);
    if (!brandData) {
      return null;
    }
    if (brandData.processedFile && brandData.processedFile.key !== '') {
      return brandData.processedFile;
    } else if (brandData.rawFile && brandData.rawFile.key !== '') {
      return brandData.rawFile;
    }
    return null;
  }

  private async loadExerciseVideoUrl(file: S3ObjectInput) {
    this.videoUrl = (await Storage.get(file.key)) as string;
    return true;
  }

  private async openVideoDialog(exercise: ExerciseDocument, brandId: string) {
    this.videoFile = this.getExerciseBrandVideo(exercise, brandId) as S3ObjectInput;
    await this.loadExerciseVideoUrl(this.videoFile);
    this.videoTitle = exercise.name;
    this.videoDialogIsOpen = true;
  }

  private closeVideoDialog() {
    this.videoDialogIsOpen = false;
    this.videoUrl = null;
  }

  public setPagination(newPage: number): void {
    this.page = newPage;
    const startPos: number = Math.max(0, this.resultSliceSize * (newPage - 1));

    this.resultSlice = this.results.slice(startPos, startPos + this.resultSliceSize);
  }

  get rowFrom() {
    return this.totalResults ? (this.page - 1) * this.resultSliceSize + 1 : 0;
  }

  get rowTo() {
    return Math.min(this.page * this.resultSliceSize, this.totalResults);
  }

  public gotoCreateNew(): void {
    const exerciseHref = Helper.routeHref({ name: 'exercise-create' });
    window.open(exerciseHref, '_blank');
  }

  public gotoExercise(exerciseId: string, step: string = '', selectBrand: string = ''): void {
    Helper.routeTo({ name: 'exercise-update', params: { id: exerciseId, step, selectBrand } });
  }

  public gotoExerciseNewTab(exerciseId: string, step: string = '', selectBrand: string = '', timeout: number = 1500): void {
    const href = Helper.routeHref({ name: 'exercise-update', params: { id: exerciseId }, query: { step, selectBrand } });
    this.exerciseHrefs.push(href);
    if (this.windowOpenTimeout) {
      clearTimeout(this.windowOpenTimeout);
    }
    this.windowOpenTimeout = setTimeout(() => {
      for (const exerciseHref of this.exerciseHrefs) {
        window.open(exerciseHref, '_blank');
      }
      this.exerciseHrefs = [];
      this.windowOpenTimeout = null;
    }, timeout);
  }

  private addSearchEvent() {
    window.addEventListener(
      'message',
      event => {
        if (event.data === 'doSearch') {
          this.doSearch();
        }
      },
      false,
    );
  }

  public gotoExerciseNewTabSingle(exerciseId: string, step: string = '', selectBrand: string = ''): void {
    this.gotoExerciseNewTab(exerciseId, step, selectBrand, 0);
  }

  private async downloadCSV(): Promise<void> {
    const qpoints = (await QpointModel.find({})).reduce(
      (accumulator, qpoint) => ({ ...accumulator, [qpoint.code]: qpoint }),
      {} as Record<string, QpointDocument>,
    );

    const columnMap: any = {
      code: 'Code',
      name: 'Name',
      movementTypes: 'Movement Types',
      equipmentTypes: 'Equipment',
      suitabilityTypes: 'Suitability Types',
    };

    BrandStore.list.forEach(brand => {
      columnMap[`brand_${brand.id}_status`] = `${brand.name} Status`;
      columnMap[`brand_${brand.id}_fileRef`] = `${brand.name} File Ref`;
      columnMap[`brand_${brand.id}_filmDate`] = `${brand.name} Film Date`;
      columnMap[`brand_${brand.id}_notes`] = `${brand.name} Notes`;
    });

    const maxQPoints = this.results.reduce((accumulator: number, result: ExerciseDocument) => Math.max(accumulator, result.qpoints.length), 0);

    for (let i = 1; i <= maxQPoints; i++) {
      columnMap[`qp${i}`] = `QP${i}`;
    }

    const results = this.results.map((result: ExerciseDocument) => {
      const brandData = BrandStore.list.reduce((accumulator: any, brand) => {
        const brandSetup = result.brandSetup.find(x => x.brandId === brand.id);

        accumulator[`brand_${brand.id}_status`] = brandSetup ? this.exerciseVideoStatusList[brandSetup.status].label : '';
        accumulator[`brand_${brand.id}_fileRef`] = brandSetup ? brandSetup.fileRef : '';
        accumulator[`brand_${brand.id}_filmDate`] = brandSetup && brandSetup.filmDate ? new Date(brandSetup.filmDate).toLocaleDateString() : '';
        accumulator[`brand_${brand.id}_notes`] = brandSetup ? brandSetup.notes : '';

        return accumulator;
      }, {});

      return {
        ...result,
        movementTypes: this.getMovementTypeList(result.movementTypes),
        equipmentTypes: this.getEquipmentList(result.equipmentTypes),
        suitabilityTypes: this.getProductTypes(result.productTypes),

        ...result.qpoints.concat(new Array(maxQPoints - result.qpoints.length).fill('')).reduce(
          (accumulator: Record<string, string>, qpoint: string, key: number) => ({
            ...accumulator,
            [`qp${key + 1}`]: qpoint !== '' && qpoints[qpoint] ? qpoints[qpoint].label : '',
          }),
          {},
        ),

        ...brandData,
      };
    });

    results.sort((a: any, b: any) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

    const csvStr: string = CSV.fromArray(results, columnMap);

    saveAs('data:application/csv;charset=utf-8,' + encodeURIComponent(csvStr), 'exercise-list.csv');
  }
}
