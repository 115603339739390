import UserStore from '@/store/modules/user';
import { UserRole, ExerciseVideoStatusType } from '@/constants';
import { UserDocument } from '@/models';

const currentUser = UserStore.current as UserDocument;

export const canManage = currentUser.hasRole(UserRole.ATHLETICS, true);

export const canHandleStatus = (status: ExerciseVideoStatusType): boolean => {
  if (canManage) {
    return true;
  }

  let statusWhitelist: ExerciseVideoStatusType[] = [];

  if (currentUser.hasRole(UserRole.FILM)) {
    statusWhitelist = [
      ...statusWhitelist,
      ExerciseVideoStatusType.READY_TO_FILM,
      ExerciseVideoStatusType.READY_TO_EDIT,
      ExerciseVideoStatusType.REJECTED_REFILM,
    ];
  }

  if (currentUser.hasRole(UserRole.PROD1)) {
    statusWhitelist = [
      ...statusWhitelist,
      ExerciseVideoStatusType.READY_TO_APPROVE,
      ExerciseVideoStatusType.READY_TO_EDIT,
      ExerciseVideoStatusType.REJECTED_REFILM,
    ];
  }

  return statusWhitelist.includes(status);
};
