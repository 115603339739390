





























import { Component, Vue, Watch } from 'vue-property-decorator';
import { Helper, IStepSetup } from '@/util';
import { ExerciseModel, ExerciseDocument } from '@/models';
import ExerciseStore from '@/store/modules/exercise';
import { GreyBox } from '@/components';
import { ExerciseManageMetadata, ExerciseManageVideo } from './sections';

Component.registerHooks(['beforeRouteLeave']);

@Component({
  name: 'ExerciseManage',
  components: {
    GreyBox,
  },
})
export default class extends Vue {
  private exercise: ExerciseDocument = ExerciseStore.current as ExerciseDocument;
  private rules = Helper.schemaToRules(ExerciseModel.getSchema(), 'blur', { movementTypes: 'change', equipmentTypes: 'change' });
  private isDirty: boolean = false;

  private step = 0;
  private isValid = false;

  private steps: Array<IStepSetup> = (undefined as unknown) as Array<IStepSetup>;

  public created() {
    window.addEventListener('beforeunload', this.confirmChanges);
    this.compileValidateFlag();

    this.steps = [
      {
        id: 'metadata',
        label: 'Metadata',
        component: ExerciseManageMetadata,
      },
      {
        id: 'video',
        label: 'Video',
        component: ExerciseManageVideo,
      },
    ];

    // Go to step (if passed in query string
    const params = { ...this.$router.currentRoute.params, ...this.$router.currentRoute.query };
    const gotoStep = params.step;
    if (gotoStep) {
      const stepIndex = this.steps.findIndex(step => {
        return step.id === gotoStep;
      });
      if (stepIndex > -1) {
        this.step = stepIndex;
      }
    }
  }

  @Watch('exercise', { deep: true })
  onFormChanged(val: ExerciseDocument, oldVal: ExerciseDocument) {
    this.isDirty = true;
  }

  private async beforeRouteLeave(to: any, from: any, next: any) {
    if (this.isDirty) {
      try {
        await this.$confirm('Changes that you made may not be saved.', {
          type: 'warning',
          confirmButtonText: 'Leave',
          confirmButtonClass: 'el-button--danger',
        });
      } catch (e) {
        return;
      }
    }
    next();
  }
  private confirmChanges(event: any) {
    if (this.isDirty) {
      event.returnValue = true;
      return true;
    }
    return;
  }

  get component(): any {
    return this.getStep().component;
  }

  private getStep(step: number = this.step): IStepSetup {
    return this.steps[step];
  }

  public isLastStep(): boolean {
    return this.step + 1 === this.steps.length;
  }

  public nextStep(): void {
    if (!this.isLastStep()) {
      this.step++;
    }
  }

  public showPrevStep(): boolean {
    return this.step - 1 >= 0;
  }

  public prevStep(): void {
    if (this.showPrevStep()) {
      this.step--;
    }
  }

  public async compileValidateFlag() {
    try {
      await this.exercise.validate();

      this.isValid = true;
    } catch (e) {
      this.isValid = false;
    }
  }
  /*

  private resetStatus(brandSetup: IExerciseBrandSetup) {
    const processedFile = brandSetup.processedFile;
    if (processedFile && processedFile.key === '') {
      brandSetup.status = ExerciseVideoStatusType.READY_TO_EDIT;
    }
    const rawFile = brandSetup.rawFile;
    if (rawFile && rawFile.key === '') {
      brandSetup.status = ExerciseVideoStatusType.READY_TO_FILM;
    }
  }
*/

  public async submitForm(): Promise<void> {
    const form: any = this.$refs['form'];
    form.validate((valid: boolean) => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }

  public async saveForm(): Promise<void> {
    const isNew = this.exercise.isNew();

    try {
      await this.exercise.validate();

      const validQpoints = this.exercise.qpoints.filter(x => !!x);

      if (validQpoints.length === 0) {
        throw new Error('You must enter in atleast one valid qpoint');
      }

      let integrityPass = true;
      let integrityMsg = '';

      try {
        await this.exercise.checkIntegrity();
      } catch (e) {
        integrityMsg = (e as any).message;
        integrityPass = false;
      }

      if (!integrityPass) {
        let isCancelled = false;

        await this.$confirm(`${integrityMsg}. You can still save the exercise if you wish`, 'Integrity check failed', {
          confirmButtonText: 'Continue saving',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }).catch(() => {
          isCancelled = true;
        });

        if (isCancelled) {
          return;
        }
      }

      await this.exercise.save();

      this.isDirty = false;

      this.$message({
        message: `Exercise successfully ${isNew ? 'created' : 'updated'}.`,
        type: 'success',
      });

      if (window.opener) {
        window.opener.postMessage('doSearch', '*');
        window.close();
      } else {
        await Helper.routeTo({ name: 'exercise-list' });
      }
    } catch (e) {
      this.$alert((e as any).message, `Exercise ${isNew ? 'create' : 'update'} failed`, { type: 'error' });
    }
  }
  public async cancelForm(): Promise<void> {
    this.isDirty = false;
    if (window.opener) {
      window.close();
    } else {
      await Helper.routeTo({ name: 'exercise-list' });
    }
  }
}
