

































































import { Component, Vue } from 'vue-property-decorator';
import assert from 'assert';
import _ from 'lodash';
import { ExerciseDocument, IExerciseBrandSetup, BrandDocument } from '@/models';
import { MovementTypeOptions, EquipmentTypeOptions, ExerciseDifficultyList } from '@/constants';
import ExerciseStore from '@/store/modules/exercise';
import BrandStore from '@/store/modules/brand';
import { GreyBox, BrandChooser } from '@/components';
import { ProductType, ProductTypeList } from '@/constants';
import { canManage } from '../util';

const disableOptionsWrapper = (options: any[]) => options.map(x => ({ ...x, disabled: !canManage }));

@Component({
  name: 'ExerciseCreateMetadata',
  components: {
    GreyBox,
    BrandChooser,
  },
})
export default class extends Vue {
  private exercise: ExerciseDocument = ExerciseStore.current as ExerciseDocument;

  private productTypeMapList = ProductTypeList;
  private movementTypeOptions = disableOptionsWrapper(MovementTypeOptions);
  private equipmentOptions = disableOptionsWrapper(EquipmentTypeOptions);
  private exerciseDifficultyList = ExerciseDifficultyList;
  private brandListProxy: string[] = [];
  private canManage: boolean = (null as unknown) as boolean;

  public created() {
    this.brandListProxy = this.exercise.getSelectedBrands();
    this.canManage = canManage;

    // Select brand (if selected)
    const params = { ...this.$router.currentRoute.params, ...this.$router.currentRoute.query };
    if (canManage && params.selectBrand) {
      const uniqueBrandsSelected = new Set<string>(this.brandListProxy);
      const selectBrand = params.selectBrand as string;
      uniqueBrandsSelected.add(selectBrand);
      this.brandListProxy = Array.from(uniqueBrandsSelected);
      this.handleBrandGroupChange(this.brandListProxy, []);
    }
  }

  public filterMethod(query: any, item: any) {
    return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
  }

  private getBrandSetup(brandId: string): IExerciseBrandSetup {
    const brandSetup: IExerciseBrandSetup = this.exercise.brandSetup.find(x => x.brandId === brandId) as IExerciseBrandSetup;

    assert(brandSetup);

    return brandSetup;
  }

  private async checkExistingBrands(selectedBrands: string[]): Promise<boolean> {
    /**
     * Look for an existing brand setup that has a valid video file
     */
    const brandSetup: IExerciseBrandSetup = this.exercise.brandSetup.find(
      x => selectedBrands.indexOf(x.brandId) === -1 && (x.rawFile?.key || x.processedFile?.key),
    ) as IExerciseBrandSetup;

    if (brandSetup) {
      try {
        await this.$confirm('Brand currently has a video associated with it. Are you sure you want to delete it?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        });
      } catch (e) {
        return false;
      }
    }

    return true;
  }

  public handleBrandGroupChange(selectedBrands: string[], prevSelectedBrands: string[]): void {
    /**
     * Add the brand
     */
    if (selectedBrands.length > prevSelectedBrands.length) {
      const brandId = _.difference(selectedBrands, prevSelectedBrands).pop() as string;
      const brand: BrandDocument = BrandStore.list.find(x => x.id === brandId) as BrandDocument;
      this.exercise.addBrandSetup(brand);

      /**
       * Else we're removing it
       */
    } else {
      const brandId = _.difference(prevSelectedBrands, selectedBrands).pop() as string;
      const brandSetupIndex = this.exercise.brandSetup.findIndex(x => x.brandId === brandId);
      this.exercise.brandSetup.splice(brandSetupIndex, 1);
    }
  }
}
