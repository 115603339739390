
































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import _ from 'lodash';
import { GreyBox, FileUploader } from '@/components';
import { ExerciseVideoStatusType, StatusType, ExerciseVideoStatusList } from '@/constants';
import { ExerciseDocument, BrandDocument, IQpoint, IExerciseBrandSetup } from '@/models';
import ExerciseStore from '@/store/modules/exercise';
import QpointStore from '@/store/modules/qpoint';
import BrandStore from '@/store/modules/brand';
import { canManage } from '../util';
import { Storage } from 'aws-amplify';
import Router from '@/router';

interface QpointMap extends IQpoint {
  value: string;
  lower: string;
  pos: number;
}

@Component({
  name: 'ExerciseCreateVideo',
  components: {
    GreyBox,
    FileUploader,
  },
})
export default class extends Vue {
  private exercise: ExerciseDocument = ExerciseStore.current as ExerciseDocument;
  private canManage: boolean = (null as unknown) as boolean;
  private qpointMap: QpointMap[] = [];
  private qpointsWrapper: string[] = [];
  private videoGuideBrand: IExerciseBrandSetup | undefined = this.exercise.brandSetup.find(brandSetup => brandSetup.isGuide);

  private exerciseVideoStatusList: Record<ExerciseVideoStatusType, StatusType<ExerciseVideoStatusType>> = (undefined as unknown) as Record<
    ExerciseVideoStatusType,
    StatusType<ExerciseVideoStatusType>
  >;
  private initialS3Object: any = {
    key: '',
    name: '',
    mimetype: '',
  };
  private initStatus: any = {};
  private brandIds: string[] = [];
  private confirmClearFileDataForBrand: IExerciseBrandSetup = (undefined as unknown) as IExerciseBrandSetup;
  private showConfirmClearFileData: boolean = false;

  public async created() {
    this.exerciseVideoStatusList = ExerciseVideoStatusList;
    this.initStatus = this.loadInitStatusByBrandId();
    this.canManage = canManage;

    // Open brand accordion and scroll down to it
    const params = { ...this.$router.currentRoute.params, ...this.$router.currentRoute.query };
    const selectBrand = params.selectBrand as string;
    this.brandIds = selectBrand ? [selectBrand] : [];
    if (this.brandIds.length > 0) {
      setTimeout(() => {
        const firstBrandId = this.brandIds[0];
        const id = `brand-${firstBrandId}`;
        const element = document.getElementById(id);
        element && element.scrollIntoView();
      }, 200);
    }

    /**
     * Copy the qpoint list so we can lowercase it all once for searching purposes and also return
     * the original cased results
     */
    this.qpointMap = QpointStore.list.map((x, i) => ({ ...(x.toJSON() as IQpoint), value: x.audio, lower: x.audio.toLowerCase(), pos: i }));

    /**
     * @TODO Need to rename `qpoints` to `qpoints`
     *
     * Map so we can save the qpoint code but display the audio
     */
    this.qpointsWrapper = this.exercise.qpoints.map(x => {
      let foundQpoint: QpointMap | undefined;

      if (x) {
        foundQpoint = _.find(this.qpointMap, ['code', x]);
      }

      if (foundQpoint) {
        return foundQpoint.audio;
      }

      return '';
    });
  }

  private queryQpoints(searchStr: string, cb: (results: QpointMap[]) => void): void {
    const searchStrLower = searchStr.toLowerCase();
    const mapResults: QpointMap[] = this.qpointMap.filter(x => x.lower.indexOf(searchStrLower) !== -1);

    /**
     * This is so the results that match at the start of the cue point get sorted first on the list
     */
    mapResults.sort((a, b) => {
      const isA = a.lower.indexOf(searchStrLower) === 0;
      const isB = b.lower.indexOf(searchStrLower) === 0;

      if (isA && isB) {
        return 0;
      } else if (isA) {
        return -1;
      } else {
        return 1;
      }
    });

    cb(mapResults);
  }

  handleQpointSelect(pos: number, selected: QpointMap): void {
    this.exercise.qpoints[pos] = selected.code;
  }

  addQpoint(): void {
    this.exercise.qpoints.push('');
    this.qpointsWrapper.push('');
  }

  removeQpoint(pos: number): void {
    this.exercise.qpoints.splice(pos, 1);
    this.qpointsWrapper.splice(pos, 1);
  }

  private handleStatusChange(brandSetup: IExerciseBrandSetup, status: ExerciseVideoStatusType): void {
    const selectedStatus = this.exerciseVideoStatusList[status] as StatusType<ExerciseVideoStatusType>;
    if (selectedStatus.initialState) {
      this.showConfirmClearFileData = true;
      this.confirmClearFileDataForBrand = brandSetup;
    }
  }

  private clearFileData() {
    const brandSetup = this.confirmClearFileDataForBrand;
    brandSetup.rawFile = this.initialS3Object;
    brandSetup.processedFile = this.initialS3Object;
    brandSetup.fileRef = '';
    brandSetup.filmDate = undefined;
    brandSetup.notes = '';
    brandSetup.lastUsed = null;
    brandSetup.amountUsed = null;
    brandSetup.isGuide = false;
    brandSetup.isLongLoop = false;
    brandSetup.prodTeamNotes = '';

    this.showConfirmClearFileData = false;
  }

  /**
   * @TODO Is only here as deleting brands do not check to see if they are in use by an exercise
   */
  private getFilteredBrandSetup(): Array<{ key: number; setup: IExerciseBrandSetup }> {
    const brandIdList = BrandStore.list.map(x => x.id);

    return this.exercise.brandSetup.reduce((accumulator, x, i) => {
      if (brandIdList.includes(x.brandId)) {
        accumulator.push({ key: i, setup: x });
      }

      return accumulator;
    }, [] as { key: number; setup: IExerciseBrandSetup }[]);
  }

  private getBrandName(brandId: string): string {
    const brand: BrandDocument = BrandStore.list.find(x => x.id === brandId) as BrandDocument;

    return brand.name as string;
  }

  private getBrandAndTrainerName(brandId: string): string {
    const brand: BrandDocument = BrandStore.list.find(x => x.id === brandId) as BrandDocument;

    return `${brand.name} (${brand.trainer.name})`;
  }

  public loadInitStatusByBrandId() {
    const initStatus: Record<string, string> = {};
    for (const brand of this.exercise.brandSetup) {
      const id = brand.brandId;
      initStatus[id] = brand.status;
    }
    return initStatus;
  }

  private showRawVideo(brandId: string): boolean {
    const brandSetup = this.exercise.brandSetup.find(x => x.brandId === brandId);

    return !brandSetup || brandSetup.status !== ExerciseVideoStatusType.APPROVED;
  }

  private isRawVideoUploaded(brandSetup: IExerciseBrandSetup) {
    const rawFile = brandSetup.rawFile;
    return rawFile && rawFile.key ? true : false;
  }

  private isStatusDisabled(brandSetup: IExerciseBrandSetup) {
    /*
    const rawUploaded = brandSetup.rawFile && brandSetup.rawFile.key !== '';
    if (!rawUploaded) {
      return true;
    }
*/
    return false;
  }

  private isProcessedVideoUploaded(brandSetup: IExerciseBrandSetup) {
    const processedFile = brandSetup.processedFile;
    return processedFile && processedFile.key ? true : false;
  }

  private isFileDetailsRequired(status: ExerciseVideoStatusType) {
    return (
      status === ExerciseVideoStatusType.READY_TO_UPLOAD ||
      status === ExerciseVideoStatusType.READY_TO_EDIT ||
      status === ExerciseVideoStatusType.READY_TO_APPROVE ||
      status === ExerciseVideoStatusType.APPROVED
    );
  }

  private isRawFileRequired(status: ExerciseVideoStatusType) {
    return (
      status === ExerciseVideoStatusType.READY_TO_EDIT || status === ExerciseVideoStatusType.READY_TO_APPROVE || status === ExerciseVideoStatusType.APPROVED
    );
  }

  private isProcessedFileRequired(status: ExerciseVideoStatusType) {
    return status === ExerciseVideoStatusType.READY_TO_APPROVE || status === ExerciseVideoStatusType.APPROVED;
  }

  private setVideoGuideBrand(brandSetup: IExerciseBrandSetup): void {
    this.videoGuideBrand = brandSetup.isGuide ? brandSetup : undefined;
  }

  private isVideoGuideBrand(): boolean {
    return this.videoGuideBrand ? true : false;
  }

  private isGuideDisabled(brandSetup: IExerciseBrandSetup) {
    if (!this.isRawVideoUploaded(brandSetup) && !this.isProcessedVideoUploaded(brandSetup)) {
      return true;
    }

    if (!this.videoGuideBrand) {
      return false;
    }

    return this.videoGuideBrand.brandId !== brandSetup.brandId;
  }

  private videoRemoved(brandSetup: IExerciseBrandSetup): void {
    if (!this.isRawVideoUploaded(brandSetup) && !this.isProcessedVideoUploaded(brandSetup)) {
      brandSetup.isGuide = false;
      this.setVideoGuideBrand(brandSetup);
    }
  }

  private async viewVideoInNewTab(brandSetup: IExerciseBrandSetup): Promise<void> {
    if (!this.isRawVideoUploaded(brandSetup) && !this.isProcessedVideoUploaded(brandSetup)) {
      // This should never happen
      return;
    }

    const videoFile = this.isProcessedVideoUploaded(brandSetup) ? brandSetup.processedFile : brandSetup.rawFile;
    const fileUrl = (await Storage.get(videoFile?.key || '')) as string;
    const location = { name: 'view-video', query: { url: fileUrl, mimetype: videoFile?.mimetype } };
    const routeData = Router.resolve(location);
    window.open(routeData.href, '_blank');
  }

  private handleLongLoopChanged(brandSetup: IExerciseBrandSetup): void {
    const longLoop = brandSetup.isLongLoop;
    this.exercise.brandSetup.map(brand => (brand.isLongLoop = longLoop));
  }

  private handleProdTeamNotesChanged(brandSetup: IExerciseBrandSetup): void {
    const activeBrandId = brandSetup.brandId;
    const activeProdTeamNotes = brandSetup.prodTeamNotes;
    // Update prod team notes across all brands from active brand
    this.exercise.brandSetup
      .filter(brand => brand.brandId !== activeBrandId)
      .map(brand => {
        brand.prodTeamNotes = activeProdTeamNotes;
      });
  }
}
